<template>
	<div
			:title="$options.filters.firstCapital(value)"
			class="text-truncate label--text noselect"
			:class="[labelTop ? 'caption-2': small ? 'paragraph':'sub-title-2', classes]"
	>
		{{ value | firstCapital }}
		<v-icon v-if="required" size="8" class="mb-2" color="label">mdi-star</v-icon>
	</div>
</template>

<script>
	export default {
		name: "Label",
		props: {value: {}, labelTop: {}, required: {type: Boolean}, small: {type: Boolean}, background: {}, classes: {}}
	}
</script>

<style scoped>
	.v-menu__content {
		box-shadow: none;
	}
</style>
